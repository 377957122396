import { useEffect } from 'react'
import encryptionGraphic from './images/encryption-setting.png'
import callRatingSurveyGraphic from './images/call-rating-survey.png'
import whatsappPollsGraphic from './images/whatsapp-polls.png';
import AvatarCover from './images/avatar-cover.png'
import VideoMessageCover from './images/video-message-cover.png'
import AIStickersCover from './images/ai-stickers-cover.jpeg'
import WarningDialog from './images/warning-dialog.png'
import leslieGraphic from './images/leslie.jpeg'
import periodStandardsGraphic from './images/period-standards.png'
import Header from './components/Header'
import './App.css';

function App() {
  useEffect(()=> {
    if(document.location.hash === '#contact') {
      setTimeout(()=> {
          document
            .querySelector("#contact")
            .scrollIntoView({ behavior: "smooth", block: "start" })
      }, 300)
    }
  }, [])

  return (
    <div className="App">
      <Header />
  
        <main id="main-content">
            <div class="content-wrapper">
              <div class="greetings">
                <h1 class="title">
                  Hi, I'm Leslie.
                </h1>
                <div style={{
                  fontSize: '2em',
                }}>
                </div>
              </div>
              <div class="description">
                <p style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                }}>
                  I'm a content designer who helps humans and UI make easy conversation.
                </p>
              </div>
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '65%'
              }}>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '40px',
                marginTop: '60px',
                flexBasis: '50%',
              }}>
              <div style={{
                width: '400px',
                position: 'relative',
              }}>
                <img src={VideoMessageCover} alt="Stickman" width="400" height="400" style={{
                  objectFit: 'contain',
                }} />
                <a href="/projects/whatsapp-quick-video-messages">
                    <div className="card">
                      <p className="text">WhatsApp video messages</p>
                    </div>
                </a>
              </div>
            </div>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '40px',
                marginTop: '60px',
                flexBasis: '50%',
              }}>
              <div style={{
                width: '400px',
                position: 'relative',
              }}>
                <img src={AIStickersCover} alt="Stickman" width="400" height="400" style={{
                  objectFit: 'contain',
                }} />
                <a href="/projects/whatsapp-ai-stickers">
                    <div className="card">
                      <p className="text">WhatsApp AI Stickers</p>
                    </div>
                </a>
              </div>
            </div>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '40px',
                marginTop: '60px',
                flexBasis: '50%',
              }}>
              <div style={{
                width: '400px',
                position: 'relative',
              }}>
                <img src={whatsappPollsGraphic} alt="Stickman" width="400" height="400" style={{
                  objectFit: 'contain',
                }} />
                <a href="/projects/whatsapp-polls">
                    <div className="card">
                      <p className="text">WhatsApp polls</p>
                    </div>
                </a>
              </div>
            </div>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '40px',
                marginTop: '60px',
                flexBasis: '50%',
                justifyContent: 'center',
              }}>
              <div style={{
                width: '400px',
                position: 'relative',
              }}>
                  <img src={AvatarCover} alt="Stickman" width="400" height="300" style={{
                    objectFit: 'contain',
                  }} />

                <a href="/projects/avatars-on-whatsapp">
                    <div className="card">
                      <p className="text">Avatars on WhatsApp</p>
                    </div>
                </a>
              </div>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '40px',
              marginTop: '60px',
              flexBasis: '50%',
              justifyContent: 'center',
            }}>
            <div style={{
              width: '400px',
              position: 'relative',
            }}>
                <img src={callRatingSurveyGraphic} alt="Stickman" width="400" height="350" style={{
                  objectFit: 'fill',
                }} />
                <a href="/projects/end-call-survey-redesign">
                    <div className="card">
                      <p className="text">WhatsApp end call survey</p>
                    </div>
                </a>
            </div>
          </div>


          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '60px',
            flexBasis: '50%',
          }}>
            <div style={{
              width: '400px',
              position: 'relative',
            }}>
              <img src={WarningDialog} alt="Stickman" width="400" height="400" style={{
                objectFit: 'fill',
              }}/>
              <a href="/projects/battery-restriction-dialog">
                <div className="card">
                  <p className="text">Battery restriction dialog</p>
                </div>
              </a>
              </div>
          </div>

            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '60px',
              flexBasis: '50%',
            }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '40px',
                }}>
                  <div style={{
                    width: '400px',
                    position: 'relative',
                  }}>
                    
                      <img src={encryptionGraphic} alt="Stickman" width="400" height="400" style={{
                        objectFit: 'cover',
                      }}/>
                      <a href="/projects/end-to-end-encryption-setting">
                        <div className="card">
                          <p className="text">Messenger E2E security checks (exploration)</p>
                        </div>  
                      </a>
                  </div>
                </div>
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '60px',
              flexBasis: '50%',
            }}>
                  <div style={{ width: '400px', position: 'relative' }}>
                      <img src={periodStandardsGraphic} alt="Stickman" width="400" height="400" style={{
                        objectFit: 'contain',
                      }}/>
                      <a href="/projects/period-standards">
                        <div className="card">
                          <p className="text">Period standards</p>
                        </div>
                      </a>
                  </div>
            </div>
            </div>
            </div>


            <div id="contact" style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '120px',
              marginBottom: '120px'
            }}>
              <div style={{
                width: '600px',  
              }}>
                <h2 style={{
                  textAlign: 'left',
                }}>
                  Get in touch
                </h2>

                <p style={{
                  textAlign: 'left',
                }}>
                  I'm currently scratching my head over a Figma file at WhatsApp.
                </p>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '40px',
                  gap: '20px',
                  textAlign: 'left',
                }}>
                    <img src={leslieGraphic} alt="Stickman" width="140" height="140" style={{
                        objectFit: 'cover',
                        borderRadius: '50%'
                    }} />
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'left',
                    }}>
                      <h2>
                        Leslie Chen
                      </h2>
                      <a className="item-link" href="mailto:leslieleechen@gmail.com">leslieleechen@gmail.com</a>
                    </div>
                </div>
              </div>

              </div>

        </main>
    </div>
  );
}

export default App;
