import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import EndToEndEncryptionSetting from './pages/EndToEndEncryptionSetting'
import PeriodStandards from './pages/PeriodStandards'
import EndCallSurveyRedesign from './pages/EndCallSurveyRedesign'
import BatteryRestrictionDialog from './pages/BatteryRestrictionDialog';
import Polls from './pages/Polls';
import Avatars from './pages/Avatars';
import AIStickers from './pages/AIStickers';
import VideoMessages from './pages/VideoMessages';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/projects/whatsapp-quick-video-messages" element={<VideoMessages /> } />
      <Route path="/projects/whatsapp-ai-stickers" element={<AIStickers /> } />
      <Route path="/projects/whatsapp-polls" element={<Polls />} />
      <Route path="/projects/avatars-on-whatsapp" element={<Avatars />} />
      <Route path="/projects/end-call-survey-redesign" element={<EndCallSurveyRedesign />} />
      <Route path="/projects/battery-restriction-dialog" element={<BatteryRestrictionDialog />} />
      <Route path="/projects/end-to-end-encryption-setting" element={<EndToEndEncryptionSetting />} />
      <Route path="/projects/period-standards" element={<PeriodStandards />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
