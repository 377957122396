import Header from '../components/Header'
import './PeriodStandards.scss'

function PeriodStandards() {
    return (
        <div>
            <Header />       
            <div className='content-wrapper'>
                <h3>Change WhatsApp’s period punctuation rules</h3>

                <div>
                    <h3>Context</h3>
                    <p>WhatsApp followed iOS and Android punctuation rules for our respective iOS and Android apps. These rules were different in intricate, granular ways (Don’t add a period after only one sentence on Android, but add one after each sentence when there are two or more sentences; iOS has a period after each sentence…).</p>                    
                </div>

                <div>
                    <h3>Problem</h3>
                    <p>Remembering and applying these nuances to the right platform and situation has been hard. We’ve seen many Figma comments of engineers and product designers asking each other and their content designer: “Is there a period here, and is it different for Android?” — taking up precious work time to ask around or dig into our complicated documentation again.</p>
                    <p>Our guitarists appreciate our upbeat and chirpy personality, but also want to tune their guitar fast and without a hitch to get back to their playing. So while we can be goofy, we always stay clear and to the point.</p>
                    <p>Complicated rules have resulted in inconsistency as well. iOS rules have been applied in Android system messages and visa versa.</p>
                </div>

                <p style={{ fontSize: '26px' }}>HerTuner is:</p>
                <ol>
                    <li>
                        <strong>Cheerful, but not hyper. </strong> We are positive, encouraging and earnest in what we say, but don’t add more exclamation points than necessary or use all caps. Our upbeatness is meant to energize and delight - not overwhelm or distract. 
                    </li>
                    <li>
                        <strong>Helpful, but not authoritative.</strong> We serve as a supportive friend or fellow classmate who’s happy to help a friend out if they’re struggling. This means that when our players make a mistake, we give brief pointers rather than lecture or pontificate. If they get it right, we’re quick to celebrate. We’re not a teacher in a classroom, just a fellow learner who’s a bit further up ahead.
                    </li>
                    <li>
                        <strong>Sweet, but not sappy. </strong>We exude warmth and immense care in our words, but don’t gush. We also maintain healthy boundaries in this friendship and don’t assume we’re everyone’s best friend.
                    </li>
                    <li>
                        <strong>Easygoing, but not irresponsible.</strong> We believe in having a good time and encourage our guitarists to laugh at their mistakes. However, we avoid levity and don’t take shortcuts when we’ve done something wrong, communicate highly important information or when the experience becomes frustrating. 
                    </li>
                </ol>

                <h4>Tone</h4>
                <p>
                    We’re a cheery sidekick that helps keep things light and positive, but also know when to sound more nurturing or serious when the situation calls for it.  
                </p>
                <p>
                    When our pals get that pitch right or win a mini-game on our app, we are celebratory and throw the confetti as we say “You did it!” with a big exclamation mark. If they can’t capture the right pitch after many tries, we soften our voice and soothingly suggest they go to our Help Center.       
                </p>
                <p>For game errors or bugs, we stay optimistic and let them know clearly what’s wrong and what they can expect in terms of fixes or waiting time. </p>

                <h3>The writing guidelines</h3>
                <p>
                    Guitar players start out out as early as seven years old, which is when kids usually start first grade. To ensure that users at that age could understand the in-app language, I made simplicity the most critical guiding principle. To avoid estranging first graders or the older users in middle or high school, I chose the middle ground and wrote at the fourth grade reading level. 
                </p>
                <p>
                    For more granular decisions (when writing numbers or compound words, for example), I wanted the app’s language to follow the MLA Style Manual. This is the most recognizable and accessible style amongst English writers and readers that’s used for almost all non-fiction and fiction books.
                </p>
                <p>
                    With that, I came up with the following writing rules: 
                </p>

                <ol>
                    <li>Write at a fourth grade reading level.</li>
                    <li>Keep language simple and clear.</li>
                    <li>Avoid complex or two-clause sentences.</li>
                    <li>Use the active voice.</li>
                    <li>If there's a less technical word for it, use it.</li>
                </ol>

                <p>Consult the MLA Handbook, 17th edition for grammar, abbreviation and punctuation rules.</p>

                
                <h3>Bottom Line</h3>
                <p style={{ marginBottom: '100px' }}>Children thrive in positive learning environments that address and lift their emotions. If this app wanted to connect with it’s audience, it had to sound positive, gentle and validating. This style guide is meant to be the north star for UX writers to rely on to decide the substance, timing and style of in-app communication. </p>

            </div>
        </div>
    )
}

export default PeriodStandards;