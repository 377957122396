import Header from '../components/Header'

import pollsCreatePoll from '../images/polls-create-poll.png'
import pollsCreatePoll2 from '../images/polls-create-poll-2.png'
import pollsLunchClub from '../images/polls-lunch-club.gif'
import pollsPollDetails from '../images/polls-poll-details.png'
import pollsVoteNotification from '../images/polls-vote-notification.png'
import pollsFeedback from '../images/polls-feedback.png'
import pollPic1 from '../images/poll-pic-1.png'
import pollPic2 from '../images/poll-pic-2.png'
import pollPic3 from '../images/poll-pic-3.png'
import pollPic4 from '../images/poll-pic-4.png'


function Polls() {
    return (
        <div style={{ marginBottom: '64px' }}>
            <Header />
            <div className='content-wrapper'>
                <h3>WhatsApp Polls</h3>

                <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                }}>

                    <div style={{
                        marginRight: '64px',
                        width: '600px',
                    }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                        }}>“What day works best for lunch?”</p>
                        <p>
                            Questions like these in large WhatsApp group chats led to a flurry of disorganized messages that were hard to track.
                        </p>
                        <p>
                            WhatsApp needed to help people reach a quick agreement by allowing them to gather a majority vote.
                        </p>
                        <p>
                            Our job? To design a simple flow that let users easily and quickly create a poll that others could vote on. From it, they’d decide what to do, where to go, and more. Users had demanded this feature many times in UXR sessions, and it was time to give it to them on both our Android and iOS app.
                        </p>
                    </div>
                    <img src={pollsLunchClub} alt="" width="300px" />
                </div>


                <div style={{
                    marginTop: '64px',
                }}>
                    <p style={{
                        fontSize: '26px',
                        fontWeight: 700,
                        marginBottom: 0,
                        marginTop: '32px',
                    }}>What I did as the content designer</p>
                    <p>
                        I used existing design and content patterns I found in competing products and in Android and iOS system languages to write content that helped the user go through tasks almost thoughtlessly, using the heuristic of recognition over recall. I also determined interaction behavior with my product designer, making decisions to create more ease of use – like having a new option blank come up automatically rather than make users tap an element to bring up a new option.
                    </p>
                    <p>
                        After each iteration, we tested our designs with participants from our most important markets and ensured users understood the terms I used for different concepts like “polls,” “votes,” “details,” and “view.”
                    </p>
                    <p>
                        After writing the UX copy, I acquired approvals from all product, legal, and design stakeholders.
                    </p>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '64px',
                }}>
                    <img src={pollPic1} alt="" width="240px" />
                    <img src={pollPic2} alt="" width="240px" />
                    <img src={pollPic3} alt="" width="240px" />
                    <img src={pollPic4} alt="" width="240px" />
                </div>

                <div style={{
                    marginTop: '64px',
                }}>
                    <p style={{
                        fontSize: '26px',
                        fontWeight: 700,
                        marginBottom: 0,
                        marginTop: '32px',
                    }}>Tone framework</p>
                    <p>
                        To help our users feel supported in a hectic situation where they had to wrangle a larger group, the experience needed to feel simple, efficient and easy. 
                    </p>
                    <p>
                        To that end, I chose the informative tone for directing a user through the task.
                    </p>
                    <p>
                        The strategy:
                    </p>
                    <ul>
                        <li>Get straight to the point</li>
                        <li>Use crisp, clear, unambiguous language that says what it needs to say with a minimum number of words</li>
                        <li>Use easy-to-understand phrases</li>
                        <li>Speak in shorthand when you have to so users don’t need to digest full sentences</li>
                        <li>Use progressive disclosure</li>
                    </ul>
                </div>
                

                <div style={{
                    display: 'flex',
                    marginBottom: '64px',
                    marginTop: '64px',
                    flexDirection: 'row',
                }}>
                    <div style={{
                        width: '600px',
                        marginRight: '64px',
                    }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                            marginTop: '32px',
                        }}>My key content design moments</p>
                        <p>
                        I applied a heavily instructive tone to enable the speediest actions and thought processes, keeping content to an absolute minimum so the UI looked simple and clean. I advocated for progressive disclosure and removal of unnecessary elements.
                        </p>
                        <p>
                            For example, when my product designer wanted to tell users that they couldn’t have more than twelve options or have the same option twice on a poll, I advised that we only show it as an in-line message at the most relevant time. We showed this information when users came close to the limit or accidentally created twin options instead of explaining it all in a wall of text.
                        </p>

                    </div>
                    <img src={pollsCreatePoll} alt="" width="300px" />
                </div>
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '64px',
                }}>
                    <p style={{
                        marginRight: '64px',
                        width: '600px',
                    }}>
                        I also advised we forgo fuller sentences like “Ask your poll question” and “Add options for your poll” in the titles because the experience felt too heavy and more complicated. I removed articles and kept instructions to one or two words to promote quick scannability.
                    </p>
                    <img src={pollsCreatePoll2} alt="" width="300px" />
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '64px',
                }}>
                    <p style={{
                        marginRight: '64px',
                        width: '600px',
                        marginBottom: '32px',
                    }}>
                        I worked with our UXR researcher to ask test users what was missing in our first iteration of polls, and they provided a list of wants such as progress reports, information regarding who voted on what, and more. I worked with our product designer to include and organize all these details in an appealing way on a vote details page that you can get to from the poll.
                    </p>
                    <img src={pollsPollDetails} alt="" width="300px" />
                </div>

                <p>
                    Finally, I was tasked with creating the polls notification content and trigger logic. 
                </p>
                <p style={{ marginBottom: '32px' }}>
                    Poll creators who tested our first iteration said they needed help remembering to check on their polls and gauge progress. My team decided to help them by offering push notifications about new votes that they could tap to get to their poll easily.
                </p>
                
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}>
                    <img src={pollsVoteNotification} alt="" width="800px" />
                </div>

                <p style={{ marginTop: '32px' }}>Every new person who voted triggered a “vote” notification. If they removed their vote, the notification would be redacted. When more people voted, the notification would silently update to avoid noise for the user and go away when they checked the poll.</p>
                <p>
                    This notification said the number of new votes like “1 new vote.” The word “vote” was consistent with other parts of the product experience (like the “View votes” button) and aligned with users’ mental models and the language they used according to our research studies.
                </p>
                <p>
                    “New” in “new vote(s)” indicated that this was recent activity. It helped avoid the misunderstanding that we were reporting the total number of votes so far. 
                </p>
                <p>
                    I ruled out people’s names (“Samantha Chang voted on your poll”) as those are not always known by our system, didn’t always fit, and did not give you a sense of how many people had recently voted. I also ruled out longer content options like “You got 3 new votes,” optimizing for the shortest sentence to allow for more of the poll title to be seen. It helped users get meaningful context and differentiate between polls happening across various group chats.
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '32px',
                }}>The Impact</p>

                <p style={{
                    marginBottom: '32px',
                }}>
                    Social media signals indicated to us even before we saw the data that polls were warmly received:
                </p>
            
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',        
                }}>
                    <img src={pollsFeedback} alt="" width="600px" />
                </div>

                <p style={{
                     marginTop: '32px',
                }}>
                Shortly, we learned from our data science team that we had hit the participation rate target on iOS. Participation rate on Android was at 2.3% and on a rapid growth path. Approximately 700,000 people created polls and 2.2 million people voted on them within the first thirty days of release.
                </p>
            </div>
        </div>
    )
}

export default Polls