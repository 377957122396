import Header from '../components/Header'
import OldSurvey from '../images/old-survey.png'
import NewSurvey from '../images/new-survey-android.png'
import EndCallImpact from '../images/end-call-impact.png'

import './EndCallSurveyRedesign.scss'

function EndCallSurveyRedesign() {
    return (
        <div style={{ marginBottom: '64px' }}>
            <Header />
            <div className='content-wrapper'>
                <h3>Call survey redesign</h3>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '40px',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                        }}>Problem</p>
                        <p>WhatsApp’s call survey only had four answers to choose from and covered less than 35% of bad use cases. Users could not report many common calling issues. Thus, the call quality engineering team could not be notified of or fix quality issues. Bad calling experiences abounded — unaddressed.</p>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                        }}>Objectives</p>
                        <ul>
                            <li>Add more answer options to cover 99% of common quality issues on the survey.</li>
                            <li>Use the most human, simple and common terms to describe technical audio and video issues so all users can more easily read and engage with the survey. More participation means more insights for our engineering teams to act on.</li>
                            <li>Collaborate with product design to keep the survey UI looking simple and engaging even with the added load of text.</li>
                        </ul>
                    </div>

                    <div>
                    </div>
                </div>

                <h4>Old call survey</h4>
                <img src={OldSurvey} alt="" width="100%" />
                <p>Android version (left) and the iOS version (right)</p>

                <div style={{
                    marginTop: '80px',
                }}>
                    <h4>New call survey</h4>
                    <img src={NewSurvey} alt="" width="100%" />
                    <p>Android version</p>

                    <h4 style={{
                        marginTop: '80px',
                    }}>Changes I made</h4>
                    <p>I added 13 more answer options.</p>
                    <p>I changed the stiff and technical copy (“Please rate the quality of your call” and “What was your issue?) to say <strong>“How was your call?”</strong> and <strong>“What went wrong?”</strong> to make the tone more conversational, empathetic, and inviting. </p>
                    <p>I added “Your answer helps WhatsApp improve call quality” to encourage users to participate and impact our app.</p>

                    <h4 style={{
                        marginTop: '80px',
                    }}>How I made them</h4>

                    <p>
                        <strong>The 13 new options:</strong> WhatsApp’s data science team went through all freeform user feedback ever submitted through the old survey and provided me with a list of the most common issues users wrote:  “Keeps reconnecting,” “Network problem/poor connection,” “Call unexpectedly ended,” “Blurry Image,” “Can’t hear/see the other person,” “The other person can’t hear/see me,” “Lags,” “Noise,” “Call didn’t connect,” and more. 
                    </p>
                    <p>
                        The call quality engineering manager and I determined which issues came up the most and needed to be on the survey.  I wrote a list of thirteen and made each answer option as short as possible so users can scan and finish the survey quickly. I ran my copy with the user report data scientists and UXR researchers and reiterated based on their feedback to ensure I was speaking in the same language as our users. 
                    </p>

                    <p>
                        <strong>The new copy: </strong>
                        I knew the greeting copy needed work after hearing from the data science team that out of 52 million call rating submitters, only 4% of them participated in the survey. I conducted user research sessions with the UXR team to understand the reasons for this drop-off at the first step. We discovered it was because users did not think their answers mattered and found the copy unfriendly.
                    </p>

                    <p>I let users know why their answers mattered in the copy and changed the tone in direct response to what our users said.</p>

                </div>
                <div style={{ marginTop: '80px' }}>
                    <h4>Design considerations</h4>
                    <p>I capped the answer options to thirteen because the survey UI looked dense and intimidating when we tried to add more.</p>
                    <p>I collaborated with product design to create a new tab UI that allowed us to add significantly more content in a visually appealing, organized way.</p>
                </div>

                <div style={{ marginTop: '80px' }}>
                    <h4>Impact</h4>
                    <p>The new survey unlocked deeper insights for the quality engineering team to explore.</p>
                    <p>Significantly more new issues were reported. Call rating were higher overall.</p>
                    <img src={EndCallImpact} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default EndCallSurveyRedesign