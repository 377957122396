import Header from '../components/Header'
import VerifyingDevicesFlow from '../images/verifying-devices-flow.png'
import UserEducation from '../images/user-education.png'

import './EndToEndEncryptionSetting.scss'

function EndToEndEncryptionSetting() {
    return (
        <div>
            <Header />
            <div className='content-wrapper'>
                <h3>Verifying that my chat is E2E encrypted</h3>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                     <div style={{
                         display: 'flex',
                         flexDirection: 'column',
                     }}>
                         <h4 style={{ fontSize: 28 }}>Project</h4>
                         <ul>
                             <li>
                                Design a way for users to <strong>verify that their Messenger chat is end-to-end encrypted and secure.</strong>
                             </li>
                             <li>
                                Enable users to check security alerts and verify that each chat member and device belong in their conversation.
                             </li>
                         </ul>
                    </div>           
                    <div style={{
                         display: 'flex',
                         flexDirection: 'column',
                     }}>
                         <h4 style={{ fontSize: 28 }}>Objective</h4>
                         <ul>
                             <li>
                                Prioritize needs of power users who will likely speed through the flow, but still serve standard users through accessible language and education.
                             </li>
                             <li>
                                Achieve brevity while providing all information needed to complete this complex process.
                             </li>
                         </ul>
                    </div>        
                </div>

                <div className='user-research-section'>
                    <h3>User research</h3>
                    <p>
                    Our user research team provided user personas they derived from their research sessions on Messenger’s security features and pinpointed which persona wanted this feature and what they needed it to do.
                    </p>

                    <p style={{
                        marginTop: '40px',
                        fontSize: '26px',
                        fontWeight: 400,
                    }}>
                        <strong>At Risk Users</strong>
                    </p>

                    <p>
                        <strong>Description: </strong> This project is specifically for celebrities, journalists, activists, politicians and other people who are at a higher risk of having their security compromised on Messenger. They want to ensure no one can read their messages outside of the conversation. 
                    </p>

                    <p>
                        Almost all At Risk Users understand basics of encryption and device verification, but some may require more education than others. 
                    </p>

                    <p>
                        <strong>Tone Requirements: </strong>Informative and educational (when helpful and relevant) 
                    </p>

                    <p>
                        <strong>Content requirements:</strong>
                    </p>

                    <ul>
                        <li>
                            Visibility and access to all ways to verify all devices in their chat. 
                        </li>
                        <li>
                            Notifications of all activity (like new devices being added) in their chat threads.
                        </li>
                        <li>
                            Access to information on technical concepts they’re less familiar with.
                        </li>
                        <li>
                            Reassurance at the right moments that no unauthorized party can access their messages to develop trust.
                        </li>
                    </ul>

                    <p style={{
                        marginTop: '40px',
                        fontSize: '26px',
                        fontWeight: 400,
                    }}>
                        <strong>Standard and Privacy Aware Users</strong>
                    </p>

                    <p>
                        <strong>Description: </strong> Regular users who moderately care or don’t care about security, privacy or encryption and enjoy our apps without changing the default settings. These people are not technical and rarely visit their security or privacy settings at all. They are the vast majority of our users.
                    </p>

                    <p>
                        <strong>Tone: </strong> Educational
                    </p>

                    <p>
                        <strong>Content Requirements: </strong> Brief and simple explanation of encryption and how it may enhance their experience for people who stumble across this new setting.
                    </p>
                </div>

                <div className='content-design-ideation-section'>
                    <h3>Content design ideation</h3>
                    <p>My product designer and I partnered to create a design pattern and content design for each archetype to visualize the elements and language on each flow. We combined and selected different elements to create the final version. </p>
                </div>

                <div className="verifying-devices-flow">
                    <h3>Verifying devices flow</h3>
                    <img src={VerifyingDevicesFlow} alt="" width="100%" />
                    <p>On your chat’s details page, the <strong>End-to-End Encryption</strong> setting sits under the Privacy and Support menu. </p>
                    <h4>
                    <br></br>
                        <strong>“End to End Encryption” settings</strong>
                    </h4>
                    <p>When you tap that setting, the <strong>simple definition at the top</strong> tells you what this page covers in a way that’s accessible to all our user personas. The <strong>“Learn More” link</strong> will go to our  <a href="https://www.facebook.com/help/messenger-app/786613221989782" style={{ textDecoration: 'underline' }}>help center article</a> (also written by me) to further educate you.</p>
                    <p>
                        <strong>The security alerts</strong> section below shows you all security activity regarding the chat. These alerts demonstrate that we care about informing you of all potential breaches to your chat’s security. 
                    </p>
                    <p>
                        The <strong>“Member Keys and Devices”</strong> section gives you a list of all members in the chat whose devices you can verify. When you tap on a chat member’s name, you’ll go to the person’s profile page.   
                    </p>

                    <h4 style={{
                        marginTop: '40px',
                    }}>
                        <br></br>
                        <strong>Melissa Rauff Profile </strong>
                    </h4>
                    <p>
                    On this page, the <strong>explanation at the top </strong>teaches or reminds you to check the key ID for each of the person’s devices to make sure they belong to the person you’re talking to. “Ask Melissa” also sounds human and conversational.  
                    </p>

                    <p>
                    <strong>The device labels and verification dates</strong> lets you keep track of all devices you’ve verified for the conversation. When you tap on a device option, you’ll be taken to its Device Info page.
                    </p>
                    <h4 style={{ marginTop: '40px' }}>
                    <br></br>
                        <strong>Device Info page</strong>
                    </h4>
                    <p>
                        When you go to the Device Info page, you see the key’s ID so you can compare it with the one your chat member sees over the phone or through a video call. I suggest using some safety tips and the QR key code scanner so you can scan Melissa’s QR key code in person instead. I explain how to use it in simple terms and thoughtfully add “faster” to highlight the convenience factor and our consideration of your experience.
                    </p>
                    <p>
                        (Note: The <strong>Can’t Scan Code</strong> design is still in development).
                    </p>
                </div>
                <br />
                <div className="key-education" style={{
                    marginBottom: '100px',
                }}>
                    <h3>Key education</h3>
                    <h4>
                        The user education flow
                    </h4>
                    <p>
                        On the Device Info page, you can tap the <strong>Learn More</strong> button if you’re stuck and not sure exactly what a key is and how to use it to verify your chat’s security. Tapping on it will start an education flow that explains in plain language what encryption keys are, how they function and how to use the public key to check that your Messenger conversation is end-to-end encrypted.  
                    </p>
                    <img src={UserEducation} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default EndToEndEncryptionSetting