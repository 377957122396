import Header from '../components/Header'
import quickVideoMessage from '../images/ai-stickers/quick-video-message.png'
import record from '../images/ai-stickers/record.png'
import family from '../images/ai-stickers/family.png'
import tooltipExploraton from '../images/ai-stickers/tooltip-exploration.png'
import options from '../images/ai-stickers/options.png'

function VideoMessages() {
    return (
        <div style={{ marginBottom: '64px' }}>
            <Header />
            <div className='content-wrapper'>
                <h3>Introducing video messages at WhatsApp</h3>
                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Context:</p>

                <p>
                    In 2023, WhatsApp wanted to provide a convenient and quick way to record and send short, real-time and expressive video messages to your loved ones.
                </p>

                <p>
                    From the composer in a chat, users long press on the camera icon to record and send a short (1 minute-max), circular video message.
                </p>

                <img src={quickVideoMessage} alt="" width="320px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <img src={record} alt="" width="640px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <img src={family} alt="" width="320px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    The issue with the 2023 MVP launch was that users reportedly said in user tickets that they did not know where we first put the entrypoint, which was to tap on the mic icon to turn it into a camera icon and then long press the camera. Video messages were also hard to naturally discover; user research indicated that users had no idea our feature existed. 
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Business and content goals</p>

                <p>
                We had to boost discovery of PTV by introducing it to new users in a way that:
                </p>

                <ol>
                    <li>Grabbed people’s attention, with arresting language like “New!” or with an icon</li>
                    <li>Described what the feature was and its benefits (you can more quickly record and send video messages).</li>
                    <li>Educate on how to try it (hold and press the camera).</li>
                </ol>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Principles</p>

                <ul>
                    <li>Compelling: I had to grab people’s attention and draw them in with compelling,  exclamatory, or benefit-led language.</li>
                    <li>Clarity: I had to clearly explain what this feature was and how to use it.</li>
                    <li>Brevity: I communicated with minimal, snappy language to fit all the info into a small tooltip and say all the important details before I lost users’ attention. </li>
                </ul>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Tones</p>

                <ul>
                    <li>Informative: I wanted to be direct and fit the most important info in a small space.</li>
                    <li>Affirmative: I sought to sound positive and upbeat to get users intrigued quickly, as tooltips are notorious for being ignored.</li>
                </ul>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Tooltip explorations</p>

                <p>
                    I wrote versions that tried to fulfill the aforementioned goals. The following versions went to the Archive because they scored low on the criteria I created out of those goals.
                </p>

                <img src={tooltipExploraton} alt="" width="960px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    It came down to three options that were the most compelling, explanatory and brief. 
                </p>

                <img src={options} alt="" width="960px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    After getting feedback from my content design director, marketing teams, product manager, design leads, user research stakeholders, and content reviewers, we chose to test “You can press and hold to record video messages” and “New! Press and hold to record video messages.”
                </p>

                <p>
                    We decided that the striking exclamation “New!” and addressing the user directly with “You can” were the most powerful ways to get a user to pay attention to a tooltip. The explanatory description after felt concise and comprehensive for both as well. 
                </p>

                <p>
                    The engineering team conducted an A/B test of these tooltips with a segment of users upon relaunch to see which tooltip was associated with more video message sends. The performance metrics we could log with WhatsApp’s user data collection model was how many video message sends happened within the user population that got the first tooltip versus the users who got the second tooltip. 
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Results:</p>

                <p>
                    The version with “New!” (Android: +14%, iOS: +7%) performed consistently better than the version with “You can…” (Android: +7%, iOS: +5%) on both app platforms. So, the team increased the roll out with the higher performing language.
                </p>

                <p>
                    The video messaging team was struck by how much words impact the bottom line, as 7% reflected thousands of users in our A/B test.
                </p>
            </div>
        </div>
    )
}

export default VideoMessages