import Header from '../components/Header'

import avatarGallery from '../images/avatar-gallery.png'
import sayMoreAvatars from '../images/say-more-avatars.png'
import avatarStickerTray from '../images/avatar-sticker-tray.png'
import avatarFlows from '../images/avatar-flows.png'
import spreadWord from '../images/spread-word.png'

function Avatars() {
    return (
        <div style={{ marginBottom: '64px' }}>
            <Header />
            <div className='content-wrapper'>
                <h3>Introducing Meta Avatars to WhatsApp</h3>

                <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                }}>

                    <div style={{
                        marginRight: '64px',
                    }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                        }}>“WhatsApp Avatars are the best way to easily express yourself – wherever you are in the world – so you feel closer to the people who matter most.”</p>
                        <p>
                            Meta product teams asked me to sell this value proposition to WhatsApp audiences at the right moments in the app to give Avatars the highest chance of user adoption when we released it on our surfaces. 
                        </p>
                        <p>
                            This would prove to be a rewarding exercise in tone, content placement, and timing strategy.
                        </p>
                        <p>
                            Meta Avatars on Facebook and Instagram had language, visuals, and concepts that were considerably more playful and flashy than what WhatsApp had ever offered. Our audiences were used to more serious and privacy-centric experiences like disappearing messages. They also had different perspectives, digital literacy levels, and wants than Instagram or Facebook Avatar users.
                        </p>
                        <p>
                            Leaning on research, I created promotional content that kept to Meta’s existing Avatars narrative, but highlighted the feature in a way that felt natural and compelling to WhatsApp audiences.
                        </p>
                    </div>
                </div>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>What I did as the content designer</p>
                <img src={avatarGallery} alt="" width="1000px" style={{
                    objectFit: 'contain',
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    I had to find out which aspects of Meta Avatars felt the most appealing to WhatsApp audiences in our target markets in India and Brazil. I planned to emphasize those benefits in key pop-ups and promotional in-app banners that our product team wanted to place throughout the messaging experience. 
                </p>


                <p>
                    After conducting user research sessions, I found that potential avatar users wanted to:
                </p>
                <ul>
                    <li>Express their feelings through stickers uniquely made for them</li>
                    <li>Have their avatar represent them on their profile photo</li>
                    <li>Have fun with highly customizable avatars that could look just like them </li>
                </ul>

                <p>
                    I had my value propositions derived from research. Now I needed to carefully select the right tones, leverage simple language, and employ a human voice to write our main upselling content.
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Tone framework</p>

                <p>
                    From user testing, we learned that WhatsApp users appreciated a sense of privacy and security; they loved the end-to-end encryption aspect of the app and were very wary about using a feature from Meta, given its earlier history with user privacy. This is why I chose to incorporate reassuring tone and content in our promotional content. Working with the privacy team to convey this tone, I:
                </p>

                <ul>
                    <li>Anticipated and addressed concerns around who can see their avatar by transparently saying who could</li>
                    <li>Assured users that all their personal messages and calls were always private and inaccessible</li>
                    <li>Was transparent about what happens if they create an avatar and what data we retain when they do</li>
                </ul>

                <p>We also wanted WhatsApp users to feel excited about Avatars coming to WhatsApp and see the possibilities and most exciting use cases that addressed their biggest wants. I used encouraging and affirming tones to:</p>

                <ul>
                    <li>Point out what people can do after completing a step in the creation flow</li>
                    <li>Tell people the benefits of taking this action, but without sounding like a salesperson</li>
                    <li>Use language that’s positive, motivating, and confident</li>
                    <li>Try to put an optimistic spin on things</li>
                    <li>Use active tense</li>
                    <li>Use personal pronouns when talking about positive things to drive attachment</li>
                </ul>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '64px',
                }}>
                    <div style={{
                        marginRight: '64px',
                        width: '600px',
                    }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                            marginTop: '32px',
                        }}>Applying the research and tone</p>
                        <p>
                            I wanted to write a promotional pop-up that arrested user attention immediately and made a memorable first impression, applying the aforementioned research findings and positive, affirmative, and reassuring tones to ensure that happened.
                        </p>
                        <p>
                            Because users had expressed being especially excited about creating a customized representation of themselves and using personalized stickers that allowed them to “say more” about how they felt in newer ways, I emphasized those aspects of avatars in the title and the first couple bullet points. I kept it to three simple, punchy bullet points to reduce cognitive load for users as well. 
                        </p>
                        <p>
                            A core WhatsApp writing principle is to use simple, easily processed words and sentence structure. I kept sentences simple and short, adding excitment and energy through action-oriented phrases in the bullets. To sound more human and personable, I used “your” often to drive user attachment to to their potential avatar and avoided jargon. I also kept the reading level at 6th grade or lower to make the message easily digestible to users of all reading capability. 
                        </p>
                        <p>
                            I confirmed this screen’s upselling power by testing this screen in research sessions, which elicited positive user reactions.
                        </p>
                    </div>
                    <img src={sayMoreAvatars} alt="" width="300px" />
                </div>
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '64px',
                }}>
                    <p style={{
                        marginRight: '64px',
                        width: '600px',
                    }}>
                        To take advantage of our users’ obvious enthusiasm for stickers, I strategically placed a banner about Avatars in the sticker tray, where sticker enthusiasts would definitely find this message and be drawn to the edge these new customizable stickers had on others. In the copy, I spoke to our main selling point again by saying how these stickers represent you.
                    </p>
                    <img src={avatarStickerTray} alt="" width="300px" />
                </div>

            
                <p>To achieve the business objective of making Avatars go viral, my product designer and I created flows that allowed users to easily share the feature with friends and family on WhatsApp from their sticker page.</p>
                <img src={avatarFlows} alt="" width="1000px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }}
                />
                <p>
                    We used words like “personalized” and “express,” which were the buzz words that users mentioned most often when talking about why they wanted to use Avatars.
                </p>

                <p>
                We also created another way for you to spread the word by simply using avatar stickers. When a user sends an avatar sticker to a receiver who doesn’t have an avatar yet, the receiver can tap on the avatar sticker as an entrypoint to creating their own avatar.
                </p>
                <img src={spreadWord} alt="" width="600px" style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                This was the upselling content strategy that surfaced Avatars to users with the most compelling message at the most relevant moments, boosting the discoverability and interest that led to the performance numbers below.
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>The Impact</p>

                <p>
                2.7 million avatars were created with half a million daily active users within a week of release. The completion rate for creating an avatar was at a whopping 38% compared to the 15% average across Meta’s other apps.
                </p>

                <p>
                My team proved that Meta-based services can succeed on Whatsapp for the first time in Meta history, paving the way for more cross company products.
                </p>
            </div>
        </div>
    )
}

export default Avatars