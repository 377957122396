import Header from '../components/Header'
import FullWarningDialog from '../images/full-warning-dialog.png'

function BatteryRestrictionDialog() {
    return (
        <div>
            <Header />
            <div className='content-wrapper'>
                <h3>Warning WhatsApp callers with battery restriction turned on</h3>
                <div style={{
                    marginTop: '40px',
                    flexDirection: 'row',
                    display: 'flex',
                    gap: '40px',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        <h4 style={{ marginBottom: 0 }}>Problem</h4>
                        <p>22 million WhatsApp callers had battery restriction turned on while calling on an Android device in 2022. However, audio and video issues are prone to happen on Android devices when on this setting. WhatsApp received over 470 tickets from Android users between January and May of 2022 regarding call issues that users didn’t realize were due to the setting.  </p>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        <h4 style={{ marginBottom: 0 }}>Objective</h4>
                        <p>Write a warning dialog that lets Android users know 1. what issues can happen when this setting is on and 2. teach them how to change the setting to avoid issues before they happen. </p>
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: '40px',
                    marginTop: '60px',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        <h4>The warning dialog</h4>
                        <img src={FullWarningDialog} alt="" style={{
                            maxWidth: '100%',
                        }}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        <h4 style={{ marginBottom: 18 }}>The content strategy</h4>
                        <p>
                            <strong>Timing:</strong> I timed and placed this dialog to show up only to users who had this restriction on and had hopped on a call, increasing the message's relevance to the user. 
                        </p>
                        <p>
                            <strong>Tone:</strong> I wanted to be direct and instructive. Users would want to get back to their call, so I got straight to point and only said the most important information in as few words as possible. Being empathetic or humorous would only distract or annoy users here.  
                        </p>

                        <p>
                            <strong>Vocabulary:</strong> I used the words battery usage, restricted and unrestrict because these words are used on most users’ Android device settings and are familiar to them.
                        </p>

                        <p>
                            <strong>Punctuation:</strong> I removed the period from the end of the last sentence purposefully to make it similar to the Unrestricted UI element you’ll see or hear on your screen reader on your device settings. 
                        </p>
                    </div>
                </div>

                <h4 style={{ marginBottom: 18, marginTop: 80 }}>Challenges</h4>
                <p>
                    <strong>Changing steps: </strong>Every Android manufacturer has different settings (and steps) to turn off battery restriction. There are also over 24,000 distinct Android devices. To help me avoid having to write 24,000 different dialogs, the research team determined that 95% of the 22 million Android users used Samsung devices at OS versions of 8 and higher (the rest used Google Pixel).  I created several dialog versions that successfully covered all variations of these steps across Samsung and Google devices and their versions. Engineering changed the error dialog dynamically to show the right dialog to the device user.
                </p>
                <p>
                    <strong>Limited capability:</strong> I asked the engineering team if the Settings button could go directly to the battery restriction setting. I wanted to delete the second sentence and avoid making users memorize steps. Unfortunately, the engineering team said this wasn’t a capability they had and that users would only get guidance through content.
                </p>

                <h4 style={{ marginBottom: 18 }}>Impact</h4>
                <p style={{ marginBottom: 100 }}>
                    Over 10% of total Android users turned off their battery restriction setting in response to this dialog. User reports were cut in half within a month and had decreased incrementally over the course of 2022.
                </p>
            </div>
        </div>
    )
}

export default BatteryRestrictionDialog