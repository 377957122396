import './Header.scss'

function Header() {
    return (
        <header style={{
            width: '100%',
            height: '6.25rem',
            background: 'rgb(255, 255, 255)',
          }}>
            <div className="App-header" style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: '62.5rem',
                  margin: '0px auto',
                  padding: '0px 2.5rem',
            }}>
              <a href="/" style={{
                display: 'inline-block',
                textDecoration: 'none',
                color: 'rgb(244, 107, 63)',
                cursor: 'pointer',
              }}>
                <div class="home">
                  lc.
                </div>
              </a>
              <nav class="nav-bar">
                <a class="cta-button" href="/#contact">
                  Contact
                </a>
              </nav>
            </div>
          </header>
    )
}

export default Header