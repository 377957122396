import Header from '../components/Header'
import needs from '../images/ai-stickers/needs.png'
import banner from '../images/ai-stickers/banner.png'
import promotional from '../images/ai-stickers/promotional.png'
import genaiExamples from '../images/ai-stickers/genai-examples.png'
import strategies from '../images/ai-stickers/strategies.png'
import report from '../images/ai-stickers/report.png'
import results from '../images/ai-stickers/results.png'

function AIStickers() {
    return (
        <div style={{ marginBottom: '64px' }}>
            <Header />
            <div className='content-wrapper'>
                <h3>Introducing AI Stickers at WhatsApp</h3>

                <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                }}>

                    <div style={{
                        marginRight: '64px',
                    }}>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                        }}>
                            AI stickers
                        </p>

                        <p>
                            Meta's family of apps dove right into the generative AI market in 2023 with their release of AI stickers, which allowed users to generate any sticker they could imagine by just typing a brief description in a text bar.
                        </p>
                        <p>
                            I owned the upselling and educational content strategy for AI stickers on WhatsApp, where AI stickers could solve for the lack of variety and general gaps in our current sticker pack offerings.
                        </p>

                    </div>
                </div>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Users' recurring wants</p>
                <p>
                    The pain point messaging users consistently complained in our annual research studies was that existing WhatsApp stickers felt irrelevant to their conversations, childish, visually complex, too colorful, and personally or culturally off.
                </p>

                <p>
                    We made the quick conclusion that creating your own sticker to fit your exact needs would help with this problem.
                </p>


                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Their needs</p>

                <p>
                WhatsApp users in particular needed more education on how to use AI to generate stickers in feature announcements and sticker creation task flows.
                </p>

                <p>
                    At Meta, WhatsApp is known to serve a global audience that is less digitally literate than that of Instagram, Messenger or Facebook. In moderated user research sessions, most WhatsApp users either did not know what AI was or did not understand how that AI could generate images on the fly. A couple individuals even said that "AI" was a customer service agent named "Al" when playing with an early prototype that let them ask an AI messaging assistant questions. 
                </p>

                <img src={needs} alt="" width="1000px" style={{
                    objectFit: 'contain',
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>Users had trouble understanding what made a good prompt or description that helped AI generate their vision. I noted that <b>users would need support on how to write an effective description that told the AI which sticker to create.</b></p>

                <p>
                To ensure we kept our user community safe, we wanted to offer a way to report offensive or harmful stickers. We'd use these user reports to train our AI technology to avoid making similar stickers that violated our community policies. 
                </p>

                

                <div style={{
                    display: 'flex',
                    marginBottom: '64px',
                }}>
                    <div>
                        <p style={{
                            fontSize: '26px',
                            fontWeight: 700,
                            marginBottom: 0,
                            marginTop: '32px',
                        }}>Business goals</p>
                        <p>
                            WhatsApp sought to:
                        </p>
                        <ol>
                            <li>Boost sticker sends and engagement with stickers overall in messaging</li>
                            <li>Add richer and more customized expression options for users</li>
                            <li>Include reporting of harmful or offensive stickers to maintain quality, integrity and positive user sentiment.</li>
                        </ol>

                        <p>
                        In light of users wanting the perfect sticker to send and not finding them in our current sticker offerings, we sought to give users creative control. They could ask AI to generate the right sticker that fits the moment or that conveys the particular image they have in their head.
                        </p>
                    </div>
                </div>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Content goals</p>

                <ol>
                    <li>Sell AI stickers in a way that sounded exciting, resonated with WhatsApp users, and spoke to their needs and wants in in-app announcements.</li>
                    <li>Be educational and helpful so users feel that generating AI stickers is easy to pick up by briefly explaining how the feature worked in our in-app announcement banner and walking users through the AI sticker creation flow through simple and instructive copy.</li>
                    <li>Provide clear labeling and UX copy to help users navigate to and complete sticker reporting.</li>
                </ol>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Voice and tone framework to support goals</p>

                <ol>
                    <li>Leverage our conversational and plain voice spoken when introducing generative AI stickers to remove the intimidation factor for those who are unfamiliar with AI.</li>
                    <li>Use encouraging and affirming tones to excite users and play up the newfound possibilities of expression so users feel empowered and motivated to try it.</li>
                </ol>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                    marginTop: '64px',
                }}>Goal #1: Sell and educate</p>

                <p>
                    To announce AI stickers in an educational way, I wove in an explanation on how to generate AI stickers in our feature announcement banner, our first touchpoint. All you had to do to "create" a sticker was to describe it. "Describing" sounded clear, low-effort and quick according to content leads. Users who have never used AI will feel informed and more confident about moving forward knowing what AI sticker generation generally entails before they start.
                </p>

                <p>
                To stay exciting and draw users in, I chose "create" as my word choice because users had expressed excitement over that word when we mentioned our plans to let you create your own stickers in early messaging-related research. I also added an exclamation mark to uplevel the sense of excitement and incorporated the inspiring message that you could "turn any idea into a sticker" - highlighting the range of possibilities and creative power users have.
                </p>

                <img src={banner} alt="" width="320px" style={{
                    objectFit: 'contain',
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                
                <p>When users tap this banner, they are taken to a promotional sheet that goes over the privacy implications, but still should further excite users and explain how to use this feature.</p>
                
                <img src={promotional} alt="" width="320px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />


                <p>
                    I devised the feature positioning and value propositions based on what I knew users wanted from our research:
                </p>

                <ol>
                    <li>Find the exact, custom stickers that fits the mood you want to show or the moment you’re sharing with others</li>
                    <li>Use your imagination and hone your creativity</li>
                </ol>

                <p>
                    To speak to our user's desire to find the best sticker, I mentioned in the first bullet that you could generate any kind of sticker that was "just right for your chat." 
                </p>

                <p>
                    To speak to the second, I talked loftily about how you could "bring stickers to life" with your own descriptions that you come up with.
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Goal #2: Guide and educate users through sticker creation</p>

                <p>
                    To make the explanatory UX language and terminology feel familiar and aligned to industry patterns, I researched what words other generative AI products used:
                </p>

                <img src={genaiExamples} alt="" width="640px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    In our competitors' UX and help content, the convention was to ask users to "type a description" and provide helpful examples of good descriptions. Knowing our users needed their hand to be held in order to feel confident and successful, I followed these solid strategies.
                </p>

                <img src={strategies} alt="" width="640px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    Once you tap "Continue" on the promotional page, you are brought to the AI sticker creation page. You see a helpful example of a prompt along with its generated sticker to give you an idea of what to type in the bar. I created several example prompts that included an object, action and scene (elements of a good prompt for users to know) for the engineer team to implement and have on rotation so users had a diversity of examples. 
                </p>

                <p>
                    If you still need more help, the clear instruction to "Describe new sticker to generate" tells you exactly what to do.
                </p>

                <p>
                (To be more concise, we initially had the placeholder say "Describe a new sticker." However, users in later post-MVP research sessions thought they were searching for an existing sticker pre-generated by AI. We added "to generate" to make it clear you were creating these stickers in real-time.)
                </p>

                <p>
                    After they generated stickers, I provided users a hint to tap on the sticker in order to send it as it didn't seem wholly clear.
                </p>

                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Goal #3: Help users report stickers</p>

                <p>
                    I used existing WhatsApp design and content patterns for reporting people and messages for reporting stickers in order to use what was already familiar to users. 
                </p>

                <p>
                    Today, you could long press on a person or message to see options to report it. Other actions like reporting could also be found in overflow menus. I clearly labeled the entrypoint to reporting as "Report to Meta."
                </p>

                <p>
                    After you tap “Report to Meta”, I explained what would happen next to assuage any user qualms around sending their WhatsApp data to Meta. I inferred that Meta would use this data for positive ends and only to ensure the AI technology avoided creating harmful images. This content reviewed by legal, privacy, policy, company communications, and integrity teams.
                </p>

                <img src={report} alt="" width="640px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

                <p>
                    Placing the report starting point in both these familiar and expected places ensured that users could discover the report function.
                </p>


                <p style={{
                    fontSize: '26px',
                    fontWeight: 700,
                    marginBottom: 0,
                }}>Results</p>

                <p>
                    Upon releasing AI stickers, we hit over 100,000 daily active users consistently, a milestone we wanted to hit.
                </p>

                <img src={results} alt="" width="640px"  style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }} />

            </div>
        </div>
    )
}

export default AIStickers